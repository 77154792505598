<template>
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Cajas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Gastos Operativos - Costos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Gastos Operativos - Costos
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div class="flex mt-3 lg:mt-0">
        <Button
          label="NUEVO COSTO"
          v-if="'Costo Crear' in auth.user.permissions"
          class="p-button-outlined mr-2 p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nuevo Costo'"
          @click="openModalCosto"
        >
        </Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_costos"
        dataKey="id"
        :value="costos"
        :paginator="true"
        :loading="cargando"
        :rows="50"
        :filters="buscar"
        :rowHover="true"
        class="p-datatable-sm"
        stripedRows
        showGridlines
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 200]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Servicios"
        responsiveLayout="scroll"
        filterDisplay="menu"
        :lazy="true"
        :totalRecords="totalRecords"
        v-model:filters="buscar"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        selectionMode="single click"
        @row-select="editarCosto($event.data)"
      >
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column
          field="id"
          header="CÓD."
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          style="width: 30px"
          :class="'text-right'"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CÓD..."
            />
          </template>
        </Column>
        <Column field="fecha_registro" header="FECHA REGISTRO"></Column>
        <Column
          field="servicio.descripcion"
          header="SERVICIO/DESCRIPCION"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          style="font-weight: bold"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CÓD..."
            />
          </template>
        </Column>
        <Column field="costo" header="MONTO" style="text-align: right">
        </Column>
        <Column field="observacion" header="OBSERVACION"></Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <CostoCreate
        :show="costoCrearDialog"
        :costo="{ ...costo }"
        @closeModal="cerrarModalCosto"
        @actualizarListado="cargarCostos"
      />
    </div>
  </div>
</template>
    
    <script>
import { FilterMatchMode } from "primevue/api";
import CostoCreate from "@/module/costos/CostoCreate.vue";
import CostoService from "@/service/CostoService";

import { useAuth } from "@/stores";
export default {
  components: {
    CostoCreate,
  },

  data() {
    return {
      cargando: true,
      costos: null,
      costoCrearDialog: false,
      costo: {},
      totalRecords: 0,
      claseEstado: ["status-instock", "status-instock", " status-outofstock"],
      buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        "servicio.descripcion": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      lazyParams: {},
    };
  },
  costoService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.costoService = new CostoService();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt_costos.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.cargarCostos();
  },
  methods: {
    onPage(event) {
      this.lazyParams = event;
      this.cargarCostos();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarCostos();
    },
    acciones(datos) {
      return [
        {
          label: "Editar Costo",
          disabled: "Costo Editar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-pencil",
          command: () => {
            this.editarCosto(datos);
          },
        },
        {
          label: "Eliminar",
          disabled:
            "Costo Eliminar" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-trash",
          command: () => {
            this.eliminarCosto(datos.id);
          },
        },
      ];
    },
    eliminarCosto(id) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL COSTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.costoService.eliminarCosto(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarCostos();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    cargarCostos() {
      this.cargando = true;
      this.costoService
        .getCostosAll({ dt_params: JSON.stringify(this.lazyParams) })
        .then((data) => {
          this.totalRecords = data.costos.total;
          this.costos = data.costos.data;
          this.cargando = false;
        });
    },
    editarCosto(datos) {
      this.costo = { ...datos };
      this.costo.costo = parseFloat(datos.costo);
      this.costo.estado = {
        label: datos.estado_texto,
        value: datos.estado,
      };
      this.costoCrearDialog = true;
    },

    openModalCosto() {
      this.costo = {
        estado: { label: "ACTIVO", value: 1 },
      };
      this.costoCrearDialog = true;
    },

    cerrarModalCosto() {
      this.costoCrearDialog = false;
    },
  },
};
</script>
    <style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>  