<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '550px' }"
    header="Gastos Operativos (Costos)"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-10">
          <label for="servicio_id"
            ><strong>SELECCIONE EL GASTO OPERATIVO (COSTO): </strong>
            <span class="p-invalid">*</span></label
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="servicioSelect"
              :options="servicios"
              :filter="true"
              optionLabel="descripcion"
              placeholder="Seleccione un servicio..."
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.descripcion }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button"
              v-if="'Servicio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              v.tooltip.top="'Nuevo Servicio'"
              @click="activarServicioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.servicio_id">{{
            errors.servicio_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="costo"
            ><strong>MONTO: </strong><span class="p-invalid">*</span></label
          >
          <InputNumber
            id="costo"
            v-model="data_costo.costo"
            :min="0"
            :max="9999999999"
            :style="{ textAlign: 'right' }"
          >
          </InputNumber>
          <small class="p-invalid" v-if="errors.costo">{{
            errors.costo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="fecha_registro"
            ><strong>FECHA REGISTRO: </strong><span class="p-invalid">*</span>
          </label>
          <Calendar
            id="fecha_registro"
            v-model="data_costo.fecha_registro"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
          <small class="p-invalid" v-if="errors.fecha_registro">{{
            errors.fecha_registro[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <label for="observacion"><strong>OBSERVACI&Oacute;N: </strong></label>
          <Textarea
            id="observacion"
            v-model.trim="data_costo.observacion"
            rows="1"
            autoResize
            placeholder="Ingrese una observacion..."
          />
          <small class="p-invalid" v-if="errors.observacion">{{
            errors.observacion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-12" v-show="data_costo.id">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_costo.estado"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="data_costo.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-check"
        class="p-button-primary p-button-lg"
        @click="saveCosto"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
  <ServicioCreate
    :show="servicioModal"
    :servicio="servicio"
    @closeModal="cerrarModalServicio"
    @actualizarListado="cargarServicios"
  ></ServicioCreate>
</template>
<script>
import CostoService from "@/service/CostoService";
import ServicioService from "@/service/ServicioService";
import ServicioCreate from "@/module/servicios/ServicioCreate.vue";
import { useAuth } from "@/stores";

export default {
  components: {
    ServicioCreate,
  },
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    costo: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      mostrarModal: this.show,
      data_costo: this.costo,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      servicios: [],
      servicioSelect: null,
      servicioModal: false,
      servicio: { estado: { label: "ACTIVO", value: 1 } },
    };
  },
  costoService: null,
  servicioService: null,
  auth: null,
  created() {
    this.costoService = new CostoService();
    this.servicioService = new ServicioService();
    this.auth = useAuth();
  },

  mounted() {
    this.cargarServicios();
  },
  methods: {
    activarServicioModal() {
      this.servicioModal = true;
      this.servicio = {
        estado: { label: "ACTIVO", value: 1 },
      };
    },
    cerrarModalServicio() {
      this.servicioModal = false;
      this.servicio = {};
      this.servicioSelect = {};
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.data_costo = {};
      this.servicioSelect = null;

      this.$emit("closeModal");
    },
    saveCosto() {
      if (this.servicioSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Debe seleccionar un servicio",
          life: 3000,
        });
        return;
      }

      this.errors = {};
      this.enviado = true;
      if (this.costo.id) {
        let costo_enviar = {
          ...this.data_costo,
          estado: this.data_costo.estado.value,
          servicio_id: this.servicioSelect.id ?? null,
        };
        this.costoService.updatedCosto(costo_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        let tmp = this.data_costo;
        this.data_costo = {
          ...this.data_costo,
          estado: this.data_costo.estado.value,
          servicio_id: this.servicioSelect.id ?? null,
        };
        this.costoService.saveCosto(this.data_costo).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_costo = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
    cargarServicios(servicio = null) {
      this.servicioService
        .getServiciosAll()
        .then((response) => {
          this.servicios = response.servicios ?? [];
          if (servicio) {
            this.servicios.forEach((element) => {
              if (element.id == servicio.id) {
                this.servicioSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    costo(val) {
      this.data_costo = val;
      if (this.data_costo.id == null) {
        this.data_costo.fecha_registro = new Date();
        return;
      }
      this.servicioSelect = this.servicios.find(
        (servicio) => servicio.id == this.data_costo.servicio_id ?? null
      );
    },
  },
};
</script>
    <style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>